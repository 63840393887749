import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import { useNavigate } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import Constants from '../../Components/UiKit/Constants';
import { useTranslation } from 'react-i18next';
import PathConstants from '../../Helper/Routes/PathConstants';

const locales = {
    en: { title: 'English' },
    es: { title: 'Spanish' },
};

const SmokeFreeHospital = () => {

    var parse = require('html-react-parser');
    const { t, i18n } = useTranslation();

    const navigate = useNavigate();
    const handleRedirectionClick = (path) => {
        navigate(`${path}`);
    }


    return (
        <div className='innerPages'>

            <div className='innerPageHeader'></div>
            <div className='breadcrumbs'>
                <Container>
                    <h2>{parse(t('nosmoking.title'))}</h2>
                    <ul className="bread-list">
                        <li><a onClick={() => handleRedirectionClick(PathConstants.HOME)}>{parse(t('home.title'))}</a></li>
                        <li><i className="fa fa-angle-right" aria-hidden="true"></i></li>
                        <li className="active">{parse(t('nosmoking.title'))}</li>
                    </ul>
                </Container>
            </div>

            <div className='hospitalMapPageContant'>
                <Container className='sectionPadding'>
                    <h1 style={{ textAlign: 'center' }}>Coming Soon...</h1>
                </Container>
            </div>

        </div>
    )
}

export default SmokeFreeHospital