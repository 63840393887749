import FbIcons from '../../../Assets/images/facebook-fill.svg';
import AtIcon from '../../../Assets/images/at.svg';
import Logo from '../../../Assets/images/logo.png';
import ColoredLogo from '../../../Assets/images/coloredLogo.png';
import CallIcon from '../../../Assets/images/phone-line.svg';
import tiktokIcon from '../../../Assets/images/tiktok.svg';
import Instagram from '../../../Assets/images/instagram.svg';
import YouTubeIcon from '../../../Assets/images/youtube.svg';
import CallWhiteIcon from '../../../Assets/images/phone-line.png';
import locationIcons from '../../../Assets/images/location-icons.png';
import MailIcon from '../../../Assets/images/mail.png';
import languageIcon from '../../../Assets/images/language.svg';
import noSmokingIcon from '../../../Assets/images/no-smoking-icons.svg';
import visits from '../../../Assets/images/visits-icon.png'
import treeIcon from '../../../Assets/images/tree-icon.png';
import BlogImgOne from '../../../Assets/images/blogImg01.jpg';
import Menu from '../../../Assets/images/menu.png';
import SliderImg1 from '../../../Assets/images/slider01.jpg';
import SliderImg2 from '../../../Assets/images/slider02.jpg';
import SliderImg3 from '../../../Assets/images/slider03.JPEG';
import SliderImg4 from '../../../Assets/images/slider04.jpg';
import SliderImg5 from '../../../Assets/images/slider05.jpg';
//import SliderImg5 from '../../../Assets/images/starbucks-logo.jpg';
import serviceThumbBg from '../../../Assets/images/ser-thumb-bg.png';
import pharmacyIcons from '../../../Assets/images/pharmacy.png';
import emergencieIcons from '../../../Assets/images/emergencie-icons.png';
import pathologyIcons from '../../../Assets/images/pathology-icons.png';
import bloodBankIcons from '../../../Assets/images/blood-bank.png';
import clinicalLaboratoryIcons from '../../../Assets/images/clinicalLaboratory-icons.png';
import imageIcons from '../../../Assets/images/Imaging-icons.png';
import physicalTherapy from '../../../Assets/images/physical-therapy-icons.png';
import parkingIcons from '../../../Assets/images/car-parking-icons.png';
import HomeAbout from '../../../Assets/images/homeAbout.jpg';
import shape1 from '../../../Assets/images/shape1.png';
import shape2 from '../../../Assets/images/shape2.png';
import EmergenciesImg from '../../../Assets/images/emergencyImg.png';
import AmbulanceIcon from '../../../Assets/images/ambulance-icon.png';
import EmergancyCall from '../../../Assets/images/emergancyCall-icon.png';
import specializedImg from '../../../Assets/images/specializedImg.jpg';
import building from '../../../Assets/images/building.png';
import resonica1 from '../../../Assets/images/resonancia-magnetica_magnetic-resonance/Resonancia magnetica1.jpg';
import resonica2 from '../../../Assets/images/resonancia-magnetica_magnetic-resonance/Resonancia magnetica2.jpg';
import resonica3 from '../../../Assets/images/resonancia-magnetica_magnetic-resonance/Resonancia magnetica3.jpg';
import resonica4 from '../../../Assets/images/resonancia-magnetica_magnetic-resonance/Resonancia magnetica4.jpg';
import resonica5 from '../../../Assets/images/resonancia-magnetica_magnetic-resonance/aVAF_2310.jpg';
import resonica6 from '../../../Assets/images/resonancia-magnetica_magnetic-resonance/aVAF_2332.jpg';
import resonica7 from '../../../Assets/images/resonancia-magnetica_magnetic-resonance/aVAF_2339.jpg';
import resonica8 from '../../../Assets/images/resonancia-magnetica_magnetic-resonance/resonica8.jpg';
import resonica9 from '../../../Assets/images/resonancia-magnetica_magnetic-resonance/resonica9.jpg';
import resonica10 from '../../../Assets/images/resonancia-magnetica_magnetic-resonance/resonica10.jpg';
import computedTomographyImg1 from '../../../Assets/images/tomografia_tomography/Tomografia1.jpg';
import computedTomographyImg2 from '../../../Assets/images/tomografia_tomography/Tomografia2.jpg';
import computedTomographyImg3 from '../../../Assets/images/tomografia_tomography/Tomography3.jpg';
import computedTomographyImg4 from '../../../Assets/images/tomografia_tomography/Tomography4.jpg';
import visitorImg from '../../../Assets/images/visiters_new.jpg';

import cashIcon from '../../../Assets/images/PaymentIcons/cash.png'
import cardIcon from '../../../Assets/images/PaymentIcons/credit-card.png'
import paymentTransferIcon from '../../../Assets/images/PaymentIcons/money-transfer.png'
import link1 from '../../../Assets/images/Icons/link1.png'
import link2 from '../../../Assets/images/Icons/link2.png'
import more from '../../../Assets/images/Icons/more.png'
import call from '../../../Assets/images/Icons/call.png'
import call_new from '../../../Assets/images/Icons/call_new_one.gif'
import email from '../../../Assets/images/Icons/email1.png'
import email_new from '../../../Assets/images/Icons/email_new_one.gif'
import whatsapp from '../../../Assets/images/Icons/whatsapp.png'
import whatsapp1 from '../../../Assets/images/Icons/whatsapp.png'
import whatsapp_new from '../../../Assets/images/Icons/whatsapp_new_one.gif'

// Language flag
import english from "../../../Assets/images/flags/united-kingdom.png";
import spain from "../../../Assets/images/flags/flag.png";
// pharmacy
import pharmacy1 from '../../../Assets/images/pharmacy/pharmacy01.jpg';
import pharmacy2 from '../../../Assets/images/pharmacy/pharmacy02.jpg';
import pharmacy3 from '../../../Assets/images/pharmacy/pharmacy03.jpg';
import pharmacy4 from '../../../Assets/images/pharmacy/pharmacy04.jpg';
import pharmacy5 from '../../../Assets/images/pharmacy/pharmacy05.jpg';
import pharmacy6 from '../../../Assets/images/pharmacy/pharmacy06.jpg';
import pharmacy7 from '../../../Assets/images/pharmacy/pharmacy07.jpg';
import pharmacylogo from '../../../Assets/images/pharmacy/xls.png';
// X-Ray
import xray1 from '../../../Assets/images/x-ray/x-ray1.jpg';
import xray2 from '../../../Assets/images/x-ray/x-ray2.jpg';
import xray3 from '../../../Assets/images/x-ray/x-ray3.jpg';
// ultrasound
import contrased1 from '../../../Assets/images/Contrased_studies/contrased01.jpg'
import contrased2 from '../../../Assets/images/Contrased_studies/contrased02.jpg'
import contrased3 from '../../../Assets/images/Contrased_studies/contrased03.jpg'
import contrased4 from '../../../Assets/images/Contrased_studies/contrased04.jpg'
import contrased5 from '../../../Assets/images/Contrased_studies/contrased05.jpg'
import ultrasound1 from '../../../Assets/images/ultrasound/utrasound1.jpg'
import ultrasound2 from '../../../Assets/images/ultrasound/utrasound2.jpg'
import ultrasound3 from '../../../Assets/images/ultrasound/utrasound3.jpg'
import ultrasound4 from '../../../Assets/images/ultrasound/utrasound4.jpg'
import ultrasound5 from '../../../Assets/images/ultrasound/ultrasound5.jpg'
import ultrasound6 from '../../../Assets/images/ultrasound/ultrasound6.jpg'
// Cordilogy
import cardiology1 from '../../../Assets/images/cardiology/cardiology01.jpg'
import cardiology2 from '../../../Assets/images/cardiology/cardiology02.jpg'
//all facality
import allFacality01 from '../../../Assets/images/allFacality/allFacality01.JPG'
import allFacality02 from '../../../Assets/images/allFacality/allFacality02.jpg'
import allFacality03 from '../../../Assets/images/allFacality/allFacality03.jpg'
import allFacality04 from '../../../Assets/images/allFacality/allFacality04.jpg'
import allFacality05 from '../../../Assets/images/allFacality/allFacality05.jpg'
import allFacality06 from '../../../Assets/images/allFacality/allFacality06.jpg'
import allFacality07 from '../../../Assets/images/allFacality/allFacality07.jpg'
import allFacality08 from '../../../Assets/images/allFacality/facility08.jpg'
import allFacality09 from '../../../Assets/images/allFacality/facility09.jpg'
import allFacality10 from '../../../Assets/images/allFacality/facility10.jpg'
import allFacality11 from '../../../Assets/images/allFacality/facility11.jpg'
import allFacality12 from '../../../Assets/images/allFacality/facility12.jpg'
import allFacality13 from '../../../Assets/images/allFacality/facility13.jpg'
import allFacality14 from '../../../Assets/images/allFacality/facility14.jpg'
import allFacality15 from '../../../Assets/images/allFacality/facility15.jpg'
import allFacality16 from '../../../Assets/images/allFacality/facility16.jpg'
import allFacality17 from '../../../Assets/images/allFacality/facility17.jpg'
import allFacality18 from '../../../Assets/images/allFacality/facility18.JPG'
import allFacality19 from '../../../Assets/images/allFacality/facility19.JPG'
import allFacality20 from '../../../Assets/images/allFacality/facility20.JPG'
import allFacality21 from '../../../Assets/images/allFacality/facility21.jpg'
import allFacality22 from '../../../Assets/images/allFacality/facility22.jpg'
// Nurology
import Nurology01 from '../../../Assets/images/Nurology/Nurology01.jpg'
// Clinical-Laboratory
import ClinicalLaboratory1 from '../../../Assets/images/clinical-laboratory/laboratorio1.jpg';
import ClinicalLaboratory2 from '../../../Assets/images/clinical-laboratory/laboratorio2.jpg';
import ClinicalLaboratory3 from '../../../Assets/images/clinical-laboratory/laboratorio3.jpg';
import ClinicalLaboratory4 from '../../../Assets/images/clinical-laboratory/laboratorio4.jpg';
import ClinicalLaboratory5 from '../../../Assets/images/clinical-laboratory/laboratorio5.jpg';
import ClinicalLaboratory6 from '../../../Assets/images/clinical-laboratory/laboratorio6.jpg';
import ClinicalLaboratory7 from '../../../Assets/images/clinical-laboratory/laboratorio7.jpg';
import ClinicalLaboratory9 from '../../../Assets/images/clinical-laboratory/laboratorio9.jpg';
import ClinicalLaboratory10 from '../../../Assets/images/clinical-laboratory/laboratorio10.jpg';
//Blogs
import blogs from '../../../Assets/images/blogs/blogs.png';
// Blood Bank
import bloodBank1 from '../../../Assets/images/blood-bank/blood_bank_1.jpg';
import bloodBank2 from '../../../Assets/images/blood-bank/blood_bank_2.jpg';
// nicu
import Nicu01 from "../../../Assets/images/nicu/nicu01.jpg";
import Nicu02 from "../../../Assets/images/nicu/nicu02.jpg";
import Nicu03 from "../../../Assets/images/nicu/nicu03.jpg";
import Nicu04 from "../../../Assets/images/nicu/nicu04.jpg";
import Nicu05 from "../../../Assets/images/nicu/nicu05.jpg";
import Nicu06 from "../../../Assets/images/nicu/Nicu06.jpeg";
import Nicu07 from "../../../Assets/images/nicu/Nicu07.jpeg";
import Nicu08 from "../../../Assets/images/nicu/Nicu08.jpeg";
import Nicu09 from "../../../Assets/images/nicu/Nicu09.jpeg";
//nutrition
import nutrition01 from '../../../Assets/images/nutrition/nutrition1.jpg';
import nutrition02 from '../../../Assets/images/nutrition/nutrition2.jpg';
import nutrition03 from '../../../Assets/images/nutrition/nutrition3.jpg';
import nutrition04 from '../../../Assets/images/nutrition/nutrition4.jpg';
// Physical Therapy And Rehabilitation
import physicalThrpy1 from '../../../Assets/images/physical-therapy/florencia_Terapia_1.jpg';
import physicalThrpy2 from '../../../Assets/images/physical-therapy/florencia_Terapia_2.jpg';
import physicalThrpy3 from '../../../Assets/images/physical-therapy/florencia_Terapia_3.jpg';
import physicalThrpy4 from '../../../Assets/images/physical-therapy/florencia_Terapia_4.jpg';
import physicalThrpy5 from '../../../Assets/images/physical-therapy/florencia_Terapia_5.jpg';
import physicalThrpy6 from '../../../Assets/images/physical-therapy/florencia_Terapia_6-min.jpg';
import physicalThrpy7 from '../../../Assets/images/physical-therapy/florencia_Terapia_7-min.jpg';
import physicalThrpy8 from '../../../Assets/images/physical-therapy/florencia_Terapia_8.jpg';
import physicalThrpy9 from '../../../Assets/images/physical-therapy/florencia_Terapia_9.jpg';
import physicalThrpy10 from '../../../Assets/images/physical-therapy/florencia_Terapia_10.jpg';
// Ihnaloterapia
import ihnaloterapia1 from '../../../Assets/images/Ihnaloterapia/inhalationTherapy1.jpg';
import ihnaloterapia2 from '../../../Assets/images/Ihnaloterapia/inhalationTherapy2.jpg';
import ihnaloterapia3 from '../../../Assets/images/Ihnaloterapia/inhalationTherapy3.jpg';
import ihnaloterapia4 from '../../../Assets/images/Ihnaloterapia/inhalationTherapy4.jpg';
// operating-room
import operatingRoom01 from '../../../Assets/images/oprating_room/operating-room01.jpg';
import operatingRoom02 from '../../../Assets/images/oprating_room/operating_room02.jpg';
import operatingRoom03 from '../../../Assets/images/oprating_room/operating_room03.jpg';
import operatingRoom04 from '../../../Assets/images/oprating_room/operating_room04.jpg';
import operatingRoom05 from '../../../Assets/images/oprating_room/operating_room05.jpg';
import operatingRoom06 from '../../../Assets/images/oprating_room/operating_room06.jpg';
//Biomedical
import biomedical01 from '../../../Assets/images/biomedical/biomedical01.jpg'
import biomedical02 from '../../../Assets/images/biomedical/biomedical02.jpg'
import biomedical03 from '../../../Assets/images/biomedical/biomedical03.jpg'
// hemodynamics
import hemodynamics01 from '../../../Assets/images/hemodyanmics/hemodynamics01.jpg';
import hemodynamics02 from '../../../Assets/images/hemodyanmics/hemodynamics02.jpg';
import hemodynamics03 from '../../../Assets/images/hemodyanmics/hemodynamics03.jpg';
import hemodynamics04 from '../../../Assets/images/hemodyanmics/hemodynamics04.jpg';
// intensive
import intensive01 from '../../../Assets/images/Intensive/intensive01.jpg';
import intensive02 from '../../../Assets/images/Intensive/intensive02.jpg';
import intensive03 from '../../../Assets/images/Intensive/intensive03.jpg';
import intensive04 from '../../../Assets/images/Intensive/intensive04.jpg';
import intensive05 from '../../../Assets/images/Intensive/intensive05.jpg';
import intensive06 from '../../../Assets/images/Intensive/intensive06.jpg';
import intensive07 from '../../../Assets/images/Intensive/intensive07.jpg';
import intensive08 from '../../../Assets/images/Intensive/intensive08.jpg';
import intensive09 from '../../../Assets/images/Intensive/intensive09.jpg';
import intensive10 from '../../../Assets/images/Intensive/intensive10.jpg';
import intensive11 from '../../../Assets/images/Intensive/intensive11.jpg';
import intensive12 from '../../../Assets/images/Intensive/intensive12.jpg';
import intensive13 from '../../../Assets/images/Intensive/intensive13.jpg';
// holyPlace
import holyPlace01 from '../../../Assets/images/holyPlace/holyPlace01.jpg';
import holyPlace02 from '../../../Assets/images/holyPlace/holyPlace02.jpg';
import holyPlace03 from '../../../Assets/images/holyPlace/holyPlace03.jpg'
import holyPlace04 from '../../../Assets/images/holyPlace/holyPlace04.jpg';
import holyPlace05 from '../../../Assets/images/holyPlace/holyPlace05.jpg';
// environment-Friendly
import environmentFriendly from '../../../Assets/images/environmentFriendly/environmentFriendly.jpg';
// car-parking
import carParking1 from '../../../Assets/images/parking/parking_1.jpg';
import carParking2 from '../../../Assets/images/parking/parking02.png';
import carParking3 from '../../../Assets/images/parking/parking01.png';
import carParking4 from '../../../Assets/images/parking/parking_4.jpg';
// Nursery
import nursery01 from '../../../Assets/images/nursery/nursery01.jpg';
import nursery02 from '../../../Assets/images/nursery/nursery02.jpg';
import nursery03 from '../../../Assets/images/nursery/nursery03.jpg';
import nursery04 from '../../../Assets/images/nursery/nursery04.jpg';
import nursery05 from '../../../Assets/images/nursery/nursery05.jpg';
import nursery06 from '../../../Assets/images/nursery/nursery06.jpg';
import nursery07 from '../../../Assets/images/nursery/nursery07.jpg';
// medical_tower
import MedicalTower01 from '../../../Assets/images/medical_tower/Medical_Tower01.jpg';
import MedicalTower02 from '../../../Assets/images/medical_tower/Medical_Tower02.jpg';
// starbucks
import starbucks from '../../../Assets/images/starbucks-logo.jpg';

// Pathology
import pathologyImg1 from '../../../Assets/images/pathology/laboratorio_florencia_1.jpg';
import pathologyImg2 from '../../../Assets/images/pathology/laboratorio_florencia_2.jpg';


// Nursing
import Nursing1 from "../../../Assets/images/Nursing/Nursing01.png";
import Nursing2 from "../../../Assets/images/Nursing/Nursing02.jpg";
import Nursing3 from "../../../Assets/images/Nursing/Nursing03-min.jpg";
import Nursing4 from "../../../Assets/images/Nursing/Nursing04-min.jpg";

// Restaurant
import prmoSlide01 from '../../../Assets/images/resturent/sliderProm06.jpg';
import prmoSlide02 from '../../../Assets/images/resturent/sliderProm07.jpg';
import prmoSlide03 from '../../../Assets/images/resturent/sliderProm03.jpg';
import prmoSlide04 from '../../../Assets/images/resturent/sliderProm08.jpg';
import prmoSlide05 from '../../../Assets/images/resturent/sliderProm09.jpg';
// import menuPdf from '../../../Assets/images/resturent/Carta_No_2022.pdf';


import menu1 from '../../../Assets/images/resturent/amer.jpg';
import menu2 from '../../../Assets/images/resturent/fondo.jpg';
import menu3 from '../../../Assets/images/resturent/huevo-editada.jpg';
import menu4 from '../../../Assets/images/resturent/minera.jpg';
import menu5 from '../../../Assets/images/resturent/pes.jpg';
import menu6 from '../../../Assets/images/resturent/menu6.jpg';
import menu7 from '../../../Assets/images/resturent/menu7.jpg';
import menu8 from '../../../Assets/images/resturent/menu8.jpg';
import menu9 from '../../../Assets/images/resturent/menu9.jpg';

import breakfastIMG01 from '../../../Assets/images/resturent/breakfast/breakfast1.png';
import breakfastIMG02 from '../../../Assets/images/resturent/breakfast/breakfast2.png';
import breakfastIMG03 from '../../../Assets/images/resturent/breakfast/breakfast3.png';
import breakfastIMG04 from '../../../Assets/images/resturent/breakfast/breakfast4.png';
import breakfastIMG05 from '../../../Assets/images/resturent/breakfast/breakfast5.jpg';

import lunchIMG01 from '../../../Assets/images/resturent/lunch/lunch1.png';
import lunchIMG02 from '../../../Assets/images/resturent/lunch/lunch2.png';
import lunchIMG03 from '../../../Assets/images/resturent/lunch/lunch3.png';
import lunchIMG04 from '../../../Assets/images/resturent/lunch/lunch4.png';

import cake01 from '../../../Assets/images/resturent/cakes/cake1.png';
import cake02 from '../../../Assets/images/resturent/cakes/cake2.png';
import cake03 from '../../../Assets/images/resturent/cakes/cake3.png';
import cake04 from '../../../Assets/images/resturent/cakes/cake4.jpeg';
import cake05 from '../../../Assets/images/resturent/cakes/cake5.jpeg';
import placeholder from '../../../Assets/images/resturent/cakes/placeholder.png';
import coffee01 from '../../../Assets/images/resturent/cakes/coffe1.png';
import coffee02 from '../../../Assets/images/resturent/cakes/coffe2.png';

import HomeVideoNew from '../../../Assets/videos/home.mp4';


// Insurance
import incSrcOpt1 from '../../../Assets/images/incSrcOpt1.png';
import incSrcOpt2 from '../../../Assets/images/incSrcOpt2.png';
import incSrcOpt3 from '../../../Assets/images/incSrcOpt3.png';
import incSrcOpt4 from '../../../Assets/images/incSrcOpt4.png';
import incSrcOpt5 from '../../../Assets/images/incSrcOpt5.png';
import moreInfoCode from '../../../Assets/images/moreInfoCode.jpg';
import visitas from '../../../Assets/images/visitas.pdf';
import insurer from '../../../FrontEnd/Components/Insurer.json';
import vacantesPDF from '../../../Assets/images/vacantes.pdf';

// Download App
import playStore from '../../../Assets/images/play-store.png';
import appStore from '../../../Assets/images/app-store.png';

// informes-medicos
import aigPdf from '../../../Assets/images/informes-medicos-aseguradoras/AIG/AIG-Informe-Medico.PDF';
import informemedico from '../../../Assets/images/informes-medicos-aseguradoras/AXA-GASTOS-MÉDICOS-MAYORES/INFORME-MEDICO-2022.pdf';
import informemedico2 from '../../../Assets/images/informes-medicos-aseguradoras/AXA-GASTOS-MÉDICOS-MAYORES/SOLICITUD-PAGO-DIRECTO-2022.pdf';
import generaldesalud1 from '../../../Assets/images/informes-medicos-aseguradoras/GENERAL-DE-SALUD/Aviso-de-Enfermedad-Accidente-SALUD-G-SALUD.pdf';
import generaldesalud2 from '../../../Assets/images/informes-medicos-aseguradoras/GENERAL-DE-SALUD/Informe-Medico-SALUD-G-SALUD.pdf';
import pdf6 from '../../../Assets/images/informes-medicos-aseguradoras/GNP/AVISO-DE-ACCIDENTE-GNP.pdf';
import pdf7 from '../../../Assets/images/informes-medicos-aseguradoras/GNP/FORMATO-INTERCONSULTAS-GNP.pdf';
import pdf8 from '../../../Assets/images/informes-medicos-aseguradoras/GNP/INFORME-MÉDICO.pdf';
import pdf9 from '../../../Assets/images/informes-medicos-aseguradoras/HDI/INFORME-MEDICO-HDI.pdf';
import pdf10 from '../../../Assets/images/informes-medicos-aseguradoras/INBURSA/AvisoAccidente_Enfermedad.pdf';
import pdf11 from '../../../Assets/images/informes-medicos-aseguradoras/INBURSA/InformeMedico.pdf';
import pdf12 from '../../../Assets/images/informes-medicos-aseguradoras/INTEGRA-SALUD/INFORME-MÉDICO.pdf';
import pdf13 from '../../../Assets/images/informes-medicos-aseguradoras/PLAN-SEGURO/aviso_accidente_enfermedad V10.pdf';
import pdf14 from '../../../Assets/images/informes-medicos-aseguradoras/PLAN-SEGURO/INFORME-MEDICO_V05.pdf';
import pdf15 from '../../../Assets/images/informes-medicos-aseguradoras/SEGUROS-ALLIANZ-GMM/aviso-de-accidente-o-enfermedad2022.pdf';
import pdf16 from '../../../Assets/images/informes-medicos-aseguradoras/SEGUROS-ALLIANZ-GMM/Informe-médico2022.pdf';
import pdf17 from '../../../Assets/images/informes-medicos-aseguradoras/SEGUROS-ATLAS/ATLAS-AVISO-DE-ACCIDENTE.pdf';
import pdf18 from '../../../Assets/images/informes-medicos-aseguradoras/SEGUROS-ATLAS/ATLAS-INF-MEDICO.pdf';
import pdf19 from '../../../Assets/images/informes-medicos-aseguradoras/SEGUROS-ATLAS/ENCUESTA-ATLAS.pdf';
import pdf20 from '../../../Assets/images/informes-medicos-aseguradoras/SEGUROS-BANCOMER-GMM/AvisoDeAccidente.pdf';
import pdf21 from '../../../Assets/images/informes-medicos-aseguradoras/SEGUROS-BANCOMER-GMM/InformemeEdico2022.pdf';
import pdf22 from '../../../Assets/images/informes-medicos-aseguradoras/SEGUROS-BANORTE/BANCO-MERCANTIL/BANCO-MERCANTIL.pdf';
import pdf23 from '../../../Assets/images/informes-medicos-aseguradoras/SEGUROS-BANORTE/BANORTE-GASTOS-MEDICOS-MAYORES/BANORTE-AVISO-GMM.pdf';
import pdf24 from '../../../Assets/images/informes-medicos-aseguradoras/SEGUROS-BANORTE/BANORTE-GASTOS-MEDICOS-MAYORES/BANORTE-INFORME-MEDICO-GMM.pdf';
import pdf25 from '../../../Assets/images/informes-medicos-aseguradoras/SEGUROS-BANORTE/PODER-JUDICIAL/INFORME-DE-RECLAMANTE-BANORTE-PODER-JUDICIAL.pdf';
import pdf26 from '../../../Assets/images/informes-medicos-aseguradoras/SEGUROS-BANORTE/PODER-JUDICIAL/INFORME-MEDICO-BANORTE-DEL-PODER-JUDICIAL.pdf';
import pdf27 from '../../../Assets/images/informes-medicos-aseguradoras/SEGUROS-LA-LATINOAMERIC-NA/INFORME-MEDICO-LATINO.pdf';
import pdf28 from '../../../Assets/images/informes-medicos-aseguradoras/SEGUROS-LA-LATINOAMERIC-NA/LA-LATINO-SEGUROS.pdf';
import pdf29 from '../../../Assets/images/informes-medicos-aseguradoras/SEGUROS-MAPFRE/INFORME-NUEVO-MAPFRE.pdf';
import pdf30 from '../../../Assets/images/informes-medicos-aseguradoras/SEGUROS-MAPFRE/SOLICITUD-DE-PAGO-DIRECTO-AVISO-DE-RECLAMANTE-GMM.pdf';
import pdf31 from '../../../Assets/images/informes-medicos-aseguradoras/SEGUROS-METLIFE-GMM/AVISO-METLIFE.pdf';
import pdf32 from '../../../Assets/images/informes-medicos-aseguradoras/SEGUROS-METLIFE-GMM/INFORME-MEDICO.pdf';
import pdf33 from '../../../Assets/images/informes-medicos-aseguradoras/SEGUROS-MONTERREY/AVISO-ACCIDENTE-MNYL.pdf';
import pdf34 from '../../../Assets/images/informes-medicos-aseguradoras/SEGUROS-MONTERREY/CONSENTIMIENTO-INFORMADO-MNYL.pdf';
import pdf35 from '../../../Assets/images/informes-medicos-aseguradoras/SEGUROS-MONTERREY/INFORME-MEDICO-MNYL.pdf';
import pdf36 from '../../../Assets/images/informes-medicos-aseguradoras/SEGUROS-MONTERREY/SOLICITUD-REEMBOLSO-MNYL.pdf';
import pdf37 from '../../../Assets/images/informes-medicos-aseguradoras/SEGUROS-SURA/1.SURA-Aviso-de-Accidente-o-Enfermedad.pdf';
import pdf38 from '../../../Assets/images/informes-medicos-aseguradoras/SEGUROS-SURA/2.SURA-Aviso-de-Privacidad-Integral.pdf';
import pdf39 from '../../../Assets/images/informes-medicos-aseguradoras/SEGUROS-SURA/3.SURA-Informe-Médico.pdf';
import pdf40 from '../../../Assets/images/informes-medicos-aseguradoras/SEGUROS-VE-POR-MAS/01_aviso_accidente_enefermedad.pdf';
import pdf41 from '../../../Assets/images/informes-medicos-aseguradoras/SEGUROS-VE-POR-MAS/02_informe_medico_GMM_SM008.pdf';
import pdf42 from '../../../Assets/images/informes-medicos-aseguradoras/SEGUROS-ZURICH/Formato-Informe-Medico-NUEVO-ZURICH.pdf';
import pdf43 from '../../../Assets/images/informes-medicos-aseguradoras/SEGUROS-ZURICH/Nuevo-Formato-Aviso-Autos-ZURICH.pdf';

//news
import Heart from '../../../Assets/images/news/heart.png';
import Thrombectomy from '../../../Assets/images/news/mechanical-thrombectomy.jpg';

//starbucks
import Starbucks1 from '../../../Assets/images/starbucks/StarBucks_1.jpg';
import Starbucks2 from '../../../Assets/images/starbucks/StarBucks_2.jpg';
import Starbucks3 from '../../../Assets/images/starbucks/StarBucks_3.jpg';
import Starbucks4 from '../../../Assets/images/starbucks/StarBucks_4.jpg';
import Starbucks5 from '../../../Assets/images/starbucks/StarBucks_5.jpg';
import Starbucks6 from '../../../Assets/images/starbucks/StarBucks_6.jpg';
import Starbucks7 from '../../../Assets/images/starbucks/StarBucks_7.jpg';
import Starbucks8 from '../../../Assets/images/starbucks/StarBucks_8.jpg';

//chatbox
import ChatBot from '../../../Assets/images/chatbutton.gif';
//billing
import Billing from '../../../Assets/images/billing/billing01.png';
// Certificate page 
import Inprogress from '../../../Assets/images/certificates/in_progress.jpg';
//  Imaging
import Schoolaccident from '../../../Assets/images/emergency/school-accident.jpg';
import right_arrow from '../../../Assets/images/Icons/next.png';

import inventario from '../../../Assets/images/inventario.xlsx';

const Constants = {
  FB: FbIcons,
  CASH: cashIcon,
  More: more,
  CARD: cardIcon,
  PAYMENTTRANSFER: paymentTransferIcon,
  LINK1: link1,
  LINK2: link2,
  MOBILECALL: call,
  MOBILECALL_NEW: call_new,
  EMAIL: email,
  RIGHT_ARROW: right_arrow,
  EMAIL_NEW: email_new,
  WHATSAPP: whatsapp,
  WHATSAPP1: whatsapp1,
  WHATSAPP_NEW: whatsapp_new,
  LOGO: Logo,
  COLOREDLOGO: ColoredLogo,
  CALL: CallIcon,
  TIKTOK: tiktokIcon,
  INSTA: Instagram,
  YOUTUBE: YouTubeIcon,
  CALLWHITEICON: CallWhiteIcon,
  MAIL: MailIcon,
  LOCATION: locationIcons,
  LANGUAGE: languageIcon,
  NOSMOKING: noSmokingIcon,
  TREEICON: treeIcon,
  VISITS: visits,
  BLOGIMG: BlogImgOne,
  MENU: Menu,
  AT: AtIcon,
  SLIDERIMG1: SliderImg1,
  SLIDERIMG2: SliderImg2,
  SLIDERIMG3: SliderImg3,
  SLIDERIMG4: SliderImg4,
  SLIDERIMG5: SliderImg5,
  serviceTHUMBbBG: serviceThumbBg,
  SERVICEICON1: pharmacyIcons,
  SERVICEICON2: emergencieIcons,
  SERVICEICON3: pathologyIcons,
  SERVICEICON4: bloodBankIcons,
  SERVICEICON5: clinicalLaboratoryIcons,
  SERVICEICON6: imageIcons,
  SERVICEICON7: physicalTherapy,
  PARKINGICON: parkingIcons,
  HOMEABOUTIMG: HomeAbout,
  SHAP1: shape1,
  SHAP2: shape2,
  EMERGENCIESIMAGE: EmergenciesImg,
  AMBULANCEICON: AmbulanceIcon,
  EMERGANCYCALL: EmergancyCall,
  SPECIALIZEDIMG: specializedImg,
  BUILDING: building,
  RESONICA1: resonica1,
  RESONICA2: resonica2,
  RESONICA3: resonica3,
  RESONICA4: resonica4,
  RESONICA5: resonica5,
  RESONICA6: resonica6,
  RESONICA7: resonica7,
  RESONICA8: resonica8,
  RESONICA9: resonica9,
  RESONICA10: resonica10,

  PHARMACY1: pharmacy1,
  PHARMACY2: pharmacy2,
  PHARMACY3: pharmacy3,
  PHARMACY4: pharmacy4,
  PHARMACY5: pharmacy5,
  PHARMACY6: pharmacy6,
  PHARMACY7: pharmacy7,
  PHARMACYLOGO: pharmacylogo,

  XRAY1: xray1,
  XRAY2: xray2,
  XRAY3: xray3,
  CONTRASED_STUDY1: contrased1,
  CONTRASED_STUDY2: contrased2,
  CONTRASED_STUDY3: contrased3,
  CONTRASED_STUDY4: contrased4,
  CONTRASED_STUDY5: contrased5,

  SCHOOLACCIDENT: Schoolaccident,

  ULTRASOUND1: ultrasound1,
  ULTRASOUND2: ultrasound2,
  ULTRASOUND3: ultrasound3,
  ULTRASOUND4: ultrasound4,
  ULTRASOUND5: ultrasound5,
  ULTRASOUND6: ultrasound6,
  SPAIN: spain,
  ENGLISH: english,

  CARDIOLOGY1: cardiology1,
  CARDIOLOGY2: cardiology2,
  NUROLOGY01: Nurology01,
  // NICU---
  NICU01: Nicu01,
  NICU02: Nicu02,
  NICU03: Nicu03,
  NICU04: Nicu04,
  NICU05: Nicu05,
  NICU06: Nicu06,
  NICU07: Nicu07,
  NICU08: Nicu08,
  NICU09: Nicu09,

  // Biomedical
  BIOMEDICAL01: biomedical01,
  BIOMEDICAL02: biomedical02,
  BIOMEDICAL03: biomedical03,
  // Hemodynamics
  HEMODYNAMICS01: hemodynamics01,
  HEMODYNAMICS02: hemodynamics02,
  HEMODYNAMICS03: hemodynamics03,
  HEMODYNAMICS04: hemodynamics04,
  // intensive
  INTENSIVE01: intensive01,
  INTENSIVE02: intensive02,
  INTENSIVE03: intensive03,
  INTENSIVE04: intensive04,
  INTENSIVE05: intensive05,
  INTENSIVE06: intensive06,
  INTENSIVE07: intensive07,
  INTENSIVE08: intensive08,
  INTENSIVE09: intensive09,
  INTENSIVE10: intensive10,
  INTENSIVE11: intensive11,
  INTENSIVE12: intensive12,
  INTENSIVE13: intensive13,
  // HolyPlace
  HOLYPLACE01: holyPlace01,
  HOLYPLACE02: holyPlace02,
  HOLYPLACE03: holyPlace03,
  HOLYPLACE04: holyPlace04,
  HOLYPLACE05: holyPlace05,
  // Nursery
  NURSERY01: nursery01,
  NURSERY02: nursery02,
  NURSERY03: nursery03,
  NURSERY04: nursery04,
  NURSERY05: nursery05,
  NURSERY06: nursery06,
  NURSERY07: nursery07,
  // Nursing 
  NURSING01: Nursing1,
  NURSING02: Nursing2,
  NURSING03: Nursing3,
  NURSING04: Nursing4,
  // Certifate page 
  INPROGRESS: Inprogress,

  CLINICALLABORATORY1: ClinicalLaboratory1,
  CLINICALLABORATORY2: ClinicalLaboratory2,
  CLINICALLABORATORY3: ClinicalLaboratory3,
  CLINICALLABORATORY4: ClinicalLaboratory4,
  CLINICALLABORATORY5: ClinicalLaboratory5,
  CLINICALLABORATORY6: ClinicalLaboratory6,
  CLINICALLABORATORY7: ClinicalLaboratory7,
  CLINICALLABORATORY9: ClinicalLaboratory9,
  CLINICALLABORATORY10: ClinicalLaboratory10,

  COMPUTEDTOMOGRAPHYIMG1: computedTomographyImg1,
  COMPUTEDTOMOGRAPHYIMG2: computedTomographyImg2,
  COMPUTEDTOMOGRAPHYIMG3: computedTomographyImg3,
  COMPUTEDTOMOGRAPHYIMG4: computedTomographyImg4,
  VISITORIMG: visitorImg,

  BLOODBANK1: bloodBank1,
  BLOODBANK2: bloodBank2,

  MEDICALTOWER01: MedicalTower01,
  MEDICALTOWER02: MedicalTower02,

  NUTRITION01: nutrition01,
  NUTRITION02: nutrition02,
  NUTRITION03: nutrition03,
  NUTRITION04: nutrition04,

  PHYSICALTHRPY1: physicalThrpy1,
  PHYSICALTHRPY2: physicalThrpy2,
  PHYSICALTHRPY3: physicalThrpy3,
  PHYSICALTHRPY4: physicalThrpy4,
  PHYSICALTHRPY5: physicalThrpy5,
  PHYSICALTHRPY6: physicalThrpy6,
  PHYSICALTHRPY7: physicalThrpy7,
  PHYSICALTHRPY8: physicalThrpy8,
  PHYSICALTHRPY9: physicalThrpy9,
  PHYSICALTHRPY10: physicalThrpy10,


  IHNALOTERAPIA1: ihnaloterapia1,
  IHNALOTERAPIA2: ihnaloterapia2,
  IHNALOTERAPIA3: ihnaloterapia3,
  IHNALOTERAPIA4: ihnaloterapia4,

  OPERATINGROOM01: operatingRoom01,
  OPERATINGROOM02: operatingRoom02,
  OPERATINGROOM03: operatingRoom03,
  OPERATINGROOM04: operatingRoom04,
  OPERATINGROOM05: operatingRoom05,
  OPERATINGROOM06: operatingRoom06,

  ENVIROFRIENDLY: environmentFriendly,

  CARPARKING1: carParking1,
  CARPARKING2: carParking2,
  CARPARKING3: carParking3,
  CARPARKING4: carParking4,

  STARBUKS: starbucks,

  PRMOSLIDE01: prmoSlide01,
  PRMOSLIDE02: prmoSlide02,
  PRMOSLIDE03: prmoSlide03,
  PRMOSLIDE04: prmoSlide04,
  PRMOSLIDE05: prmoSlide05,
  MENUPDF: '/static/media/Carta_Florencia.pdf',
  // MENUPDF: menuPdf,
  RESMENU1: menu1,
  RESMENU2: menu2,
  RESMENU3: menu3,
  RESMENU4: menu4,
  RESMENU5: menu5,
  RESMENU6: menu6,
  RESMENU7: menu7,
  RESMENU8: menu8,
  RESMENU9: menu9,

  INCSRCOPT1: incSrcOpt1,
  INCSRCOPT2: incSrcOpt2,
  INCSRCOPT3: incSrcOpt3,
  INCSRCOPT4: incSrcOpt4,
  MOREINFOCODE: moreInfoCode,
  VISITASPDF: visitas,
  INSURANCECOMPANIESA: insurer,
  VACANTESPDF: vacantesPDF,

  PATHOLOGYIMG1: pathologyImg1,
  PATHOLOGYIMG2: pathologyImg2,

  PLAYSTORE: playStore,
  APPSTORE: appStore,

  PRMOSLIDE01: prmoSlide01,
  PRMOSLIDE02: prmoSlide02,
  // MENUPDF: menuPdf,
  RESMENU1: menu1,
  RESMENU2: menu2,
  RESMENU3: menu3,
  RESMENU4: menu4,
  RESMENU5: menu5,
  RESMENU6: menu6,
  RESMENU7: menu7,
  RESMENU8: menu8,
  RESMENU9: menu9,

  BREAKFASTIMG01: breakfastIMG01,
  BREAKFASTIMG02: breakfastIMG02,
  BREAKFASTIMG03: breakfastIMG03,
  BREAKFASTIMG04: breakfastIMG04,
  BREAKFASTIMG05: breakfastIMG05,

  LUNCHIMG01: lunchIMG01,
  LUNCHIMG02: lunchIMG02,
  LUNCHIMG03: lunchIMG03,
  LUNCHIMG04: lunchIMG04,

  CAKE01: cake01,
  CAKE02: cake02,
  CAKE03: cake03,
  CAKE04: cake04,
  CAKE05: cake05,
  PLACEHOLDER: placeholder,
  COFFEE01: coffee01,
  COFFEE02: coffee02,

  INCSRCOPT1: incSrcOpt1,
  INCSRCOPT2: incSrcOpt2,
  INCSRCOPT3: incSrcOpt3,
  INCSRCOPT4: incSrcOpt4,
  INCSRCOPT5: incSrcOpt5,
  MOREINFOCODE: moreInfoCode,
  VISITASPDF: visitas,
  INSURANCECOMPANIESA: insurer,
  VACANTESPDF: vacantesPDF,

  PATHOLOGYIMG1: pathologyImg1,
  PATHOLOGYIMG2: pathologyImg2,
  BLOGS: blogs,

  PLAYSTORE: playStore,
  APPSTORE: appStore,

  PDF1: aigPdf,
  PDF2: informemedico,
  PDF3: informemedico2,
  PDF4: generaldesalud1,
  PDF5: generaldesalud2,
  PDF6: pdf6,
  PDF7: pdf7,
  PDF8: pdf8,
  PDF9: pdf9,
  PDF10: pdf10,
  PDF11: pdf11,
  PDF12: pdf12,
  PDF13: pdf13,
  PDF14: pdf14,
  PDF15: pdf15,
  PDF16: pdf16,
  PDF17: pdf17,
  PDF18: pdf18,
  PDF19: pdf19,
  PDF20: pdf20,
  PDF21: pdf21,
  PDF22: pdf22,
  PDF23: pdf23,
  PDF24: pdf24,
  PDF25: pdf25,
  PDF26: pdf26,
  PDF27: pdf27,
  PDF28: pdf28,
  PDF29: pdf29,
  PDF30: pdf30,
  PDF31: pdf31,
  PDF32: pdf32,
  PDF33: pdf33,
  PDF34: pdf34,
  PDF35: pdf35,
  PDF36: pdf36,
  PDF37: pdf37,
  PDF38: pdf38,
  PDF39: pdf39,
  PDF40: pdf40,
  PDF41: pdf41,
  PDF42: pdf42,
  PDF43: pdf43,

  //news
  HEART: Heart,
  THROMBECTOMY: Thrombectomy,

  //starbucks
  STARBUCKS1: Starbucks1,
  STARBUCKS2: Starbucks2,
  STARBUCKS3: Starbucks3,
  STARBUCKS4: Starbucks4,
  STARBUCKS5: Starbucks5,
  STARBUCKS6: Starbucks6,
  STARBUCKS7: Starbucks7,
  STARBUCKS8: Starbucks8,

  //chatbot
  CHATBOT: ChatBot,

  //allFacality04
  ALLFACALITY01: allFacality01,
  ALLFACALITY02: allFacality02,
  ALLFACALITY03: allFacality03,
  ALLFACALITY04: allFacality04,
  ALLFACALITY05: allFacality05,
  ALLFACALITY06: allFacality06,
  ALLFACALITY07: allFacality07,
  // yy
  ALLFACALITY08: allFacality08,
  ALLFACALITY09: allFacality09,
  ALLFACALITY10: allFacality10,
  ALLFACALITY11: allFacality11,
  ALLFACALITY12: allFacality12,
  ALLFACALITY13: allFacality13,
  ALLFACALITY014: allFacality14,
  ALLFACALITY15: allFacality15,
  ALLFACALITY16: allFacality16,
  ALLFACALITY17: allFacality17,
  ALLFACALITY18: allFacality18,
  ALLFACALITY19: allFacality19,
  ALLFACALITY20: allFacality20,
  ALLFACALITY21: allFacality21,
  ALLFACALITY22: allFacality22,
  INVENTATIOXLS : inventario,

  //billing
  BILLING: Billing,

  HOMEVIDEO_NEW: HomeVideoNew,
  P_TEXT: 'We have Internal Medicine, ER and Intensive Care Physicians, Pediatricians in all Specialties',
  "SCHOOL_ACCIDENT": "School Accident",
  "EXPENSE_INSURANCE": "Expense Insurance",
  "EMERGENCIES": "Emergencies",
  "SINISTER": "Sinister"
};

export default Constants;
