import React, { useEffect, useRef, useState } from 'react';

const ChatBot = ({ scriptSrc }) => {
  const [chatBotLoaded, setChatBotLoaded] = useState(false);
  const chatBotRef = useRef(null);

  useEffect(() => {
    if (!chatBotLoaded) {
      // Load the chatbot script when the component mounts
      const s1 = document.createElement('script');
      const s0 = document.getElementsByTagName('script')[0];
      s1.async = true;
      s1.src = scriptSrc;
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s0.parentNode.insertBefore(s1, s0);
      chatBotRef.current = s1;

      s1.onload = () => {
        setChatBotLoaded(true);
      };
    }
    if (chatBotLoaded) {
      const Tawk_API = window.Tawk_API || {};
      if (Tawk_API.showWidget) {
        Tawk_API.showWidget();
      }
    }

    return () => {
      const Tawk_API = window.Tawk_API || {};
      if (Tawk_API.hideWidget) {
        Tawk_API.hideWidget();
      }
    };
  }, [chatBotLoaded, scriptSrc]);

  return null;
};

export default ChatBot;

