import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import { Link, useNavigate } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Constants from './Constants';
import PathConstants from './../../Helper/Routes/PathConstants';

const Footer = () => {
  var parse = require('html-react-parser');
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const handleRedirectionClick = (path) => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    navigate(`${path}`);
  }

  return (
    <div id="footer">
      <Container>
        <Row>
          <Col className='col-12 col-sm-6 col-md-4'>
            <div className='footer-widget'>
              {/* <img src={Constants.COLOREDLOGO} className='footerLogo' alt="logo" /> */}
              <p>{parse(t('footerTxt.footerDesc'))}</p>
              <div className='footer-social'>
                <a href='https://www.facebook.com/hospitalflorencia/'>
                  <img src={Constants.FB} title='FaceBook' />
                </a>
                <a href='https://www.instagram.com/hospitalflorencia/'>
                  <img src={Constants.INSTA} title='Instagram' />
                </a>
                <a href='https://www.youtube.com/channel/UC9A0mS-A8f8IdrVELZ2ya-Q'>
                  <img src={Constants.YOUTUBE} title='YouTubeIcon' />
                </a>
                <a href='https://www.tiktok.com/@florenciatoluca0?_t=8fhNVyUoTZ8&_r=1'>
                  <img src={Constants.TIKTOK} title='YouTubeIcon' />
                </a>
              </div>
            </div>
          </Col>
          <Col className='col-12 col-sm-6 col-md-4'>
            <div className='footer-widget'>
              <h4 className="footer-title">{parse(t('footerTxt.ourServices'))}</h4>
              <ul id="menu-useful-links" className="menu menu-useful-links">
                <li><a onClick={() => handleRedirectionClick(PathConstants.EMERGENCIES)}>{parse(t('ServicesSubMenu.emergencies'))}</a></li>
                <li><a onClick={() => handleRedirectionClick(PathConstants.HOMEAGEIMAGING)}>{parse(t('ServicesSubMenu.imaging'))}</a></li>
                <li><a onClick={() => handleRedirectionClick(PathConstants.PHARMACY)}>{parse(t('ServicesSubMenu.pharmacy'))}</a></li>
                <li><a onClick={() => handleRedirectionClick(PathConstants.LABORATORY)}>{parse(t('ServicesSubMenu.clinicalLaboratory'))}</a></li>
                <li><a onClick={() => handleRedirectionClick(PathConstants.HOMEPATHOLOGY)}>{parse(t('ServicesSubMenu.pathology'))}</a></li>
                <li><a onClick={() => handleRedirectionClick(PathConstants.BLOODBANK)}>{parse(t('ServicesSubMenu.bloodBank'))}</a></li>
              </ul>
            </div>
          </Col>
          <Col className='col-12 col-sm-6 col-md-4'>
            <div className='footer-widget'>
              <h4 className="footer-title">{parse(t('footerTxt.contactUs'))}</h4>
              <div className='contactInfo'>
                <div>
                  <span><img src={Constants.CALLWHITEICON} alt='Mail Icon' /></span>
                  <span><a href="tel:7222140013">722 214 0013</a></span>
                </div>
                <div>
                  <span><img src={Constants.MAIL} alt='Mail Icon' /></span>
                  <span>
                    <a
                      href='mailto:servicioalcliente@hospitalflorencia.mx'>servicioalcliente@hospitalflorencia.mx</a></span>
                </div>
                <div>
                  <span><img src={Constants.MAIL} alt='Mail Icon' /></span>
                  <span>
                    <a href='mailto:info@hospitalflorencia.mx'>info@hospitalflorencia.mx</a>
                    {/* <a href='mailto:hola@hospitalflorencia.mx'>hola@hospitalflorencia.mx</a> */}
                  </span>
                </div>
                <div>
                  <span><img src={Constants.LOCATION} alt='Location Icon' /></span>
                  <span>
                    <a onClick={() => handleRedirectionClick(PathConstants.HOSPITALMAP)}>{parse(t('footerTxt.address'))}</a>
                  </span>
                </div>
              </div>
            </div>
          </Col>
          {/* <Col className='col-12 col-sm-6 col-md-3'>
            <div className='footer-widget'>
              <h4 className="footer-title">{parse(t('footerTxt.downloadApp'))}</h4>
              <div className='footer-blog'>
                <a href='#' className='d-block mb-3'><img src={Constants.PLAYSTORE} alt='Android App' className='img-fluid' style={{ maxWidth: '60%' }} /></a>
                <a href='#'><img src={Constants.APPSTORE} alt='IOS App' className='img-fluid' style={{ maxWidth: '60%' }} /></a>
              </div>
            </div>
          </Col> */}
        </Row>
      </Container>
      <div className="copyright-footer">
        <Container>
          <div className='d-flex'>
            <div><span className="copyright">{parse(t('footerTxt.copyRight'))}</span></div>
            <div>
              <a onClick={() => handleRedirectionClick(PathConstants.PRIVACY_POLICY)}>{parse(t('footerTxt.pp'))}</a>
              <a>|</a>
              <a onClick={() => handleRedirectionClick(PathConstants.TERMS_CONDITIONS)}>{parse(t('footerTxt.tou'))}</a>
            </div>
          </div>
        </Container>
      </div>
    </div>
  )
}

export default Footer