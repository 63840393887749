import React from "react";
import PathConstants from "./PathConstants";
import ChatBot from '../../Components/UiKit/chatBot.js';
import SmokeFreeHospital from "../../Pages/SmokeFreeHospital/index";
const Home = React.lazy(() => import("../../Pages/Home"));
const Emergencies = React.lazy(() => import("../../Pages/Emergencies"));
const Sinister = React.lazy(() => import("../../Pages/Emergencies/sinister"));
const HospitalMap = React.lazy(() => import("../../Pages/HospitalMap/index"));
const CarParkingMap = React.lazy(() =>
  import("../../Pages/CarParkingMap/index")
);
const NoSmoking = React.lazy(() =>
  import("../../Pages/SmokeFreeHospital/index")
);
const Visitors = React.lazy(() => import("../../Pages/Visitors/index"));
const EnvironmentFriendly = React.lazy(() =>
  import("../../Pages/EnvironmentFriendly/index")
);
const AccidentSchool = React.lazy(() =>
  import("../../Pages/Emergencies/AccidentSchool")
);
const OurFacalities = React.lazy(() => import("../../Pages/Ourfacality/index"));
const ExpensesInsurance = React.lazy(() =>
  import("../../Pages/Emergencies/ExpensesInsurance")
);
const HomePageImaging = React.lazy(() =>
  import("../../Pages/Imaging/index.js")
);
const ResonanciaMagnetica = React.lazy(() =>
  import("../../Pages/Imaging/Resonica")
);
const Tomography = React.lazy(() => import("../../Pages/Imaging/Tomography"));
const UltraSound = React.lazy(() => import("../../Pages/Imaging/UltraSound"));
const Cardiology = React.lazy(() => import("../../Pages/Imaging/Cardiology"));
const Nueology = React.lazy(() => import("../../Pages/Imaging/Neurology"));
const Xrays = React.lazy(() => import("../../Pages/Imaging/Xrays"));
const ContrasedStudies = React.lazy(() =>
  import("../../Pages/Imaging/ContrasedStudies")
);
const HomePathology = React.lazy(() =>
  import("../../Pages/Pathology/index.js")
);
const BloodBank = React.lazy(() => import("../../Pages/BloodBank/index.js"));
const Laboratory = React.lazy(() => import("../../Pages/Laboratory/index.js"));
const Pharmacy = React.lazy(() => import("../../Pages/Pharmacy/index.js"));
const Nutrition = React.lazy(() => import("../../Pages/Nutrition/index.js"));
const Nursing = React.lazy(() => import("../../Pages/Nursing/index.js"));
const Biomedical = React.lazy(() => import("../../Pages/Biomedical/index.js"));
const Cunero = React.lazy(() => import("../../Pages/Cunero/index.js"));
const Quirofano = React.lazy(() => import("../../Pages/Quirofano/index.js"));
const Inhalotherapy = React.lazy(() =>
  import("../../Pages/Inhalotherapy/index")
);
const PhysicaTherapy = React.lazy(() =>
  import("../../Pages/PhysicalTherapyRehab/index")
);
const Hemodynamics = React.lazy(() => import("../../Pages/Hemodynamics/index"));
const Oratory = React.lazy(() => import("../../Pages/Oratory/index"));
const Intensive = React.lazy(() => import("../../Pages/Intensive/index"));
const PaymentMethods = React.lazy(() =>
  import("../../Pages/PaymentMethods/index")
);
const Directory = React.lazy(() => import("../../Pages/Directory/index"));
const Nicu = React.lazy(() => import("../../Pages/Nicu/index"));
const Maternity = React.lazy(() => import("../../Pages/Maternity/index"));
const Starbucks = React.lazy(() => import("../../Pages/Starbucks/index"));
const Restuarents = React.lazy(() => import("../../Pages/Restuarents/index"));
const Breakfasts = React.lazy(() => import("../../Pages/Restuarents/Breakfasts"));
const Lunch = React.lazy(() => import("../../Pages/Restuarents/Lunch"));
const Dinner = React.lazy(() => import("../../Pages/Restuarents/Dinner"));
const Further = React.lazy(() => import("../../Pages/Further/index"));
const Billing = React.lazy(() => import("../../Pages/Billing/Billing"));
const More = React.lazy(() => import("../../Pages/More/index"));
const NationalCertificates = React.lazy(() => import("../../Pages/More/CertificatesFirst"));
const Insurance = React.lazy(() => import("../../Pages/Insurance/index"));
const TermsConditions = React.lazy(() => import("../../Pages/TermsConditions"));
const PrivacyPolicy = React.lazy(() => import("../../Pages/PrivacyPolicy"));
const PlasticSurgery = React.lazy(() => import("../../Pages/PlasticSurgery"));
const WorkWithUs = React.lazy(() => import("../../Pages/WorkWithUs"));
const OurHistory = React.lazy(() => import("../../Pages/OurHistory"));
const MedicalTower = React.lazy(() => import("../../Pages/MedicalTower"));
const InsuranceReports = React.lazy(() => import("../../Pages/Insurance/InsuranceReports"));
const Blogs = React.lazy(() => import("../../Pages/Blogs/index"));
const CoronaryByPassSurgery = React.lazy(() => import("../../Pages/News/CoronaryByPassSurgery"));
const Promotions = React.lazy(() => import("../../Pages/Promotions"));
const Dessert = React.lazy(() => import("../../Pages/Restuarents/Dessert"));
const GuidedBiopses = React.lazy(() => import("../../Pages/Imaging/GuidedBiopses"));
const VasculatBrainSurgery = React.lazy(() => import("../../Pages/VascularBrainSurgery/index"))
const MechanicalThrombectomy = React.lazy(() => import("../../Pages/News/MechanicalThrombectomy"))



const routes = [
  { path: PathConstants.HOME, element: <Home /> },
  { path: PathConstants.HOMEPAGE, element: <Home /> },
  { path: PathConstants.EMERGENCIES, element: <Emergencies /> },
  { path: PathConstants.SINISTER, element: <Sinister /> },
  { path: PathConstants.OURFACALITIES, element: <OurFacalities /> },
  { path: PathConstants.SCHOOL_ACCIDENT, element: <AccidentSchool /> },
  { path: PathConstants.EXPENSES_INSURANCE, element: <ExpensesInsurance /> },
  { path: PathConstants.HOSPITALMAP, element: <HospitalMap /> },
  { path: PathConstants.CARPARKINGMAP, element: <CarParkingMap /> },
  { path: PathConstants.NOSMOKING, element: <SmokeFreeHospital /> },
  { path: PathConstants.VISITORS, element: <Visitors /> },
  { path: PathConstants.ENVIRONMENTFRIENDLY, element: <EnvironmentFriendly /> },
  { path: PathConstants.HOMEAGEIMAGING, element: <HomePageImaging /> },
  { path: PathConstants.RESONICAMAGETICA, element: <ResonanciaMagnetica /> },
  { path: PathConstants.TOMOGRAPHY, element: <Tomography /> },
  { path: PathConstants.ULTRASOUND, element: <UltraSound /> },
  { path: PathConstants.CARDIOLOGY, element: <Cardiology /> },
  { path: PathConstants.NEUROLOGY, element: <Nueology /> },
  { path: PathConstants.XRAYS, element: <Xrays /> },
  { path: PathConstants.CONTRASTEDSTUDIES, element: <ContrasedStudies /> },
  { path: PathConstants.HOMEPATHOLOGY, element: <HomePathology /> },
  { path: PathConstants.BLOODBANK, element: <BloodBank /> },
  { path: PathConstants.LABORATORY, element: <Laboratory /> },
  { path: PathConstants.PHARMACY, element: <Pharmacy /> },
  { path: PathConstants.NUTRITION, element: <Nutrition /> },
  { path: PathConstants.NURSING, element: <Nursing /> },
  { path: PathConstants.BIOMEDICAL, element: <Biomedical /> },
  { path: PathConstants.CUNERO, element: <Cunero /> },
  { path: PathConstants.QUIROFANO, element: <Quirofano /> },
  { path: PathConstants.BLOGS, element: <Blogs /> },
  { path: PathConstants.INHALOTHERAPY, element: <Inhalotherapy /> },
  { path: PathConstants.PHYSICAL_THERAPY, element: <PhysicaTherapy /> },
  { path: PathConstants.HEMODYNAMICS, element: <Hemodynamics /> },
  { path: PathConstants.ORATORY, element: <Oratory /> },
  { path: PathConstants.INTENSIVE, element: <Intensive /> },
  { path: PathConstants.PAYMENT_METHODS, element: <PaymentMethods /> },
  { path: PathConstants.NICU, element: <Nicu /> },
  { path: PathConstants.DIRECTORY, element: <Directory /> },
  { path: PathConstants.MATERNITY, element: <Maternity /> },
  { path: PathConstants.RESTAURENT, element: <Restuarents /> },
  { path: PathConstants.STARBUCKS, element: <Starbucks /> },
  { path: PathConstants.FURTHER, element: <Further /> },
  { path: PathConstants.BILLING, element: <Billing /> },
  { path: PathConstants.MORE, element: <More /> },
  { path: PathConstants.CERTIFICATE_FIRST, element: <NationalCertificates /> },
  { path: PathConstants.INSURANCE, element: <Insurance /> },
  { path: PathConstants.TERMS_CONDITIONS, element: <TermsConditions /> },
  { path: PathConstants.PRIVACY_POLICY, element: <PrivacyPolicy /> },
  { path: PathConstants.BREAKFAST, element: <Breakfasts /> },
  { path: PathConstants.LUNCH, element: <Lunch /> },
  { path: PathConstants.DINNER, element: <Dinner /> },
  { path: PathConstants.PLASTIC_SURGRY, element: <PlasticSurgery /> },
  { path: PathConstants.WORKWITHUS, element: <WorkWithUs /> },
  { path: PathConstants.OURHISTORY, element: <OurHistory /> },
  { path: PathConstants.MEDICALTOWER, element: <MedicalTower /> },
  { path: PathConstants.INSURANCEREPORTS, element: <InsuranceReports /> },
  { path: PathConstants.CORONARYBYPASSSURGERY, element: <CoronaryByPassSurgery /> },
  { path: PathConstants.PROMOTIONS, element: <Promotions /> },
  { path: PathConstants.DESSERT, element: <Dessert /> },
  { path: PathConstants.GUIDED_BIOPSIES, element: <GuidedBiopses /> },
  { path: PathConstants.BRAIN_SURGERY, element: < VasculatBrainSurgery /> },
  { path: PathConstants.MECHANICAL_THROMBECTOMY, element: < MechanicalThrombectomy /> }

];

export default routes;
