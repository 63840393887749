import { Outlet } from "react-router-dom";
import Header from "../Components/UiKit/Header";
import Footer from "../Components/UiKit/Footer";
import {Suspense} from "react";
import Loader from "./UiKit/Loader";

export default function Layout() {
    return (
        <>
            <Header />
                <main>
                    <Suspense fallback={<Loader/>}>
                        <Outlet />
                    </Suspense>
                </main>
            <Footer />
        </>
    )
}
