import React from 'react';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import routes from "./FrontEnd/Helper/Routes/index";
import Layout from "./FrontEnd/Components/Layout";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

const App = () => {

  const router = createBrowserRouter([
    {
      element: <Layout />,
      children: routes
    }
  ]);

  return <RouterProvider router={router} />;
}

export default App;

