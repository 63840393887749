import React, { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link, useNavigate } from 'react-router-dom';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { DropDownSubMenu } from './DropDownSubMenu';
import { useTranslation } from 'react-i18next';
import Constants from './Constants';
import PathConstants from './../../Helper/Routes/PathConstants';

const locales = {
	en: { title: 'English' },
	es: { title: 'Español' },
};

const MobileMenu = ({ name, ...props }) => {

	var parse = require('html-react-parser');
	const { t, i18n } = useTranslation();

	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const menuShow = () => setShow(true);

	const navigate = useNavigate();

	const handleClick = () => {
		navigate('/ecofriendly?scrollTo=nonSmokingHospital');
	};

	const handleRedirectionClick = (path, e) => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
		navigate(path);
		handleClose()
	}

	const handleClickForChild = (path, e) => {
		e.stopPropagation();
		e.preventDefault();
		window.scrollTo({ top: 0, behavior: 'smooth' });
		navigate(path);
		handleClose()
	}

	return (
		<>

			<div className='mobileNavTop'>
				<div>
					<p>{parse(t('topBar.forSuggestions'))}</p>
					<a href='mailto:info@hospitalflorencia.mx'>info@hospitalflorencia.mx</a><br></br>
					{/*<a href='mailto:hola@hospitalflorencia.mx'>hola@hospitalflorencia.mx</a>*/}
				</div>
				<div><p>{parse(t('topBar.call'))}</p> <a href="tel:7222140013">722 214 0013</a></div>
			</div>
			<div className='mobileNavInnerBox'>
				<div className='mobile-logo'><Link to="/"><img src={Constants.LOGO} alt="Logo" className='logo' /></Link></div>
				<div id="language-dropdown">
					<img src={Constants.LANGUAGE} alt='Language Icon' />
					<select className='languageDropDown' onChange={(e) => i18n.changeLanguage(e.target.value)}>
						{Object.keys(locales).map((locale) => (
							<option key={locale} value={locale}>
								{locales[locale].title}
							</option>
						))}
					</select>
				</div>
				<div className='mobile-toggle-icon'><div onClick={menuShow}><img src={Constants.MENU} /></div></div>
			</div>

			{['end'].map((placement, idx) => (
				<Offcanvas key={idx} placement={placement} name={placement} show={show} onHide={handleClose} {...props}>
					<Offcanvas.Header closeButton>
						<Offcanvas.Title><img src={Constants.LOGO} alt="Logo" className='logo' /></Offcanvas.Title>
					</Offcanvas.Header>
					<Offcanvas.Body>

						{/* Menu */}

						<Navbar expand="lg" className="bg-body-tertiary">
							<Nav>
								<NavDropdown title={parse(t('menu.services'))} id="start-dropdown">
									<NavDropdown.Item onClick={() => handleRedirectionClick(PathConstants.EMERGENCIES)}>{parse(t('ServicesSubMenu.emergencies'))}</NavDropdown.Item>
									<NavDropdown.Item onClick={() => handleRedirectionClick(PathConstants.HOMEAGEIMAGING)}>{parse(t('ServicesSubMenu.imaging'))}</NavDropdown.Item>
									<NavDropdown.Item onClick={() => handleRedirectionClick(PathConstants.PHARMACY)}>{parse(t('ServicesSubMenu.pharmacy'))}</NavDropdown.Item>
									{/* <NavDropdown.Item onClick={() => handleRedirectionClick(PathConstants.BRAIN_SURGERY)}>{parse(t('ServicesSubMenu.brainSurgery'))}</NavDropdown.Item> */}
									<NavDropdown.Item onClick={() => handleRedirectionClick(PathConstants.LABORATORY)}>{parse(t('ServicesSubMenu.clinicalLaboratory'))}</NavDropdown.Item>
									<NavDropdown.Item onClick={() => handleRedirectionClick(PathConstants.HOMEPATHOLOGY)}>{parse(t('ServicesSubMenu.pathology'))}</NavDropdown.Item>
									<NavDropdown.Item onClick={() => handleRedirectionClick(PathConstants.BLOODBANK)}>{parse(t('ServicesSubMenu.bloodBank'))}</NavDropdown.Item>
									<NavDropdown.Item onClick={() => handleRedirectionClick(PathConstants.NURSING)}>{parse(t('ServicesSubMenu.nursing'))}</NavDropdown.Item>
									<NavDropdown.Item onClick={() => handleRedirectionClick(PathConstants.NUTRITION)}>{parse(t('ServicesSubMenu.nutrition'))}</NavDropdown.Item>
									<NavDropdown.Item onClick={() => handleRedirectionClick(PathConstants.PHYSICAL_THERAPY)}>{parse(t('ServicesSubMenu.therapy'))}</NavDropdown.Item>
									<NavDropdown.Item onClick={() => handleRedirectionClick(PathConstants.INHALOTHERAPY)}>{parse(t('ServicesSubMenu.inhalationTherapy'))}</NavDropdown.Item>
									<NavDropdown.Item onClick={() => handleRedirectionClick(PathConstants.QUIROFANO)}>{parse(t('ServicesSubMenu.operatingRoom'))}</NavDropdown.Item>
									<NavDropdown.Item onClick={() => handleRedirectionClick(PathConstants.CUNERO)}>{parse(t('ServicesSubMenu.nursery'))}</NavDropdown.Item>
									<NavDropdown.Item onClick={() => handleRedirectionClick(PathConstants.NICU)}>{parse(t('ServicesSubMenu.nicu'))}</NavDropdown.Item>
									<NavDropdown.Item onClick={() => handleRedirectionClick(PathConstants.INTENSIVE)}>{parse(t('ServicesSubMenu.intensiveTherapy'))}</NavDropdown.Item>
									<NavDropdown.Item onClick={() => handleRedirectionClick(PathConstants.HEMODYNAMICS)}>{parse(t('ServicesSubMenu.hemodynamics'))}</NavDropdown.Item>
									{/* <NavDropdown.Item onClick={() => handleRedirectionClick(PathConstants.PLASTIC_SURGRY)}>{parse(t('ServicesSubMenu.plasticSurgery'))}</NavDropdown.Item> */}
									<NavDropdown.Item onClick={() => handleRedirectionClick(PathConstants.BIOMEDICAL)}>{parse(t('ServicesSubMenu.biomedical'))}</NavDropdown.Item>
									<NavDropdown.Item onClick={() => handleRedirectionClick(PathConstants.ORATORY)}>{parse(t('ServicesSubMenu.oratory'))}</NavDropdown.Item>
									{/* <NavDropdown.Item onClick={() => handleRedirectionClick(PathConstants.STARBUCKS)}>{parse(t('ServicesSubMenu.starbucks'))}</NavDropdown.Item> */}
								</NavDropdown>
								<Nav.Link onClick={() => handleRedirectionClick(PathConstants.DIRECTORY)}>{parse(t('menu.directory'))}</Nav.Link>
								<Nav.Link onClick={() => handleRedirectionClick(PathConstants.MEDICALTOWER)}>{parse(t('menu.medicalTowers'))}</Nav.Link>
								<Nav.Link onClick={() => handleRedirectionClick(PathConstants.INSURANCE)}>{parse(t('menu.insurance'))}</Nav.Link>
								<NavDropdown title={parse(t('menu.ourFacility'))} id="start-dropdown">
									<NavDropdown.Item onClick={() => handleRedirectionClick(PathConstants.OURFACALITIES)}>{parse(t('ourFacilitySubMenu.ourFacality'))}</NavDropdown.Item>
									<NavDropdown.Item onClick={() => handleRedirectionClick(PathConstants.RESTAURENT)}>{parse(t('ourFacilitySubMenu.restaurant'))}</NavDropdown.Item>
									<NavDropdown.Item onClick={() => handleRedirectionClick(PathConstants.STARBUCKS)}>{parse(t('ServicesSubMenu.starbucks_new'))}</NavDropdown.Item>
								</NavDropdown>
								<NavDropdown title={parse(t('menu.paymentType'))} id="start-dropdown">
									<NavDropdown.Item onClick={(e) => handleRedirectionClick(PathConstants.PAYMENT_METHODS, e)}>
										{parse(t('menu.paymentForms'))}
									</NavDropdown.Item>
									<NavDropdown.Item onClick={(e) => handleClickForChild(PathConstants.BILLING, e)}>
										{parse(t('paymentFormsSubMenu.billing'))}
									</NavDropdown.Item>
								</NavDropdown>
								<NavDropdown title={parse(t('menu.further'))} id="start-dropdown">
									{/* <NavDropdown.Item onClick={() => handleRedirectionClick(PathConstants.CERTIFICATE_FIRST)}>{parse(t('furtherSubMenu.certification'))}</NavDropdown.Item> */}
									<NavDropdown.Item onClick={(e) => handleClickForChild(PathConstants.OURHISTORY, e)}>{parse(t('furtherSubMenu.ourHistory'))}</NavDropdown.Item>
									<NavDropdown.Item onClick={() => handleRedirectionClick(PathConstants.BLOGS)}>{parse(t('furtherSubMenu.blog'))}</NavDropdown.Item>
									<NavDropdown.Item onClick={(e) => handleClickForChild(PathConstants.WORKWITHUS, e)}>{parse(t('furtherSubMenu.workWith'))}</NavDropdown.Item>
									<NavDropdown.Item onClick={() => handleRedirectionClick(PathConstants.PROMOTIONS)}>{parse(t('furtherSubMenu.promotions'))}</NavDropdown.Item>
								</NavDropdown>
								<NavDropdown title={parse(t('menu.news'))} id="start-dropdown">
									<NavDropdown.Item onClick={() => handleRedirectionClick(PathConstants.CORONARYBYPASSSURGERY)}>{parse(t('newsSubMenu.coronaryByPassSurgery'))}</NavDropdown.Item>
									<NavDropdown.Item onClick={() => handleRedirectionClick(PathConstants.MECHANICAL_THROMBECTOMY)}>{parse(t('newsSubMenu.mechanicalThrombectomy'))}</NavDropdown.Item>
								</NavDropdown>
							</Nav>
						</Navbar>

						{/* Menu End */}


					</Offcanvas.Body>
				</Offcanvas>
			))}
		</>
	)
}

export default MobileMenu