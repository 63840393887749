const PathConstants = {
  HOME: "/",
  HOMEPAGE: "/home",
  EMERGENCIES: "/emergencies",
  SINISTER: "/sinister",
  SCHOOL_ACCIDENT: "/accident",
  EXPENSES_INSURANCE: "/expenses-insurance",
  HOSPITALMAP: "/hospitalmap",
  CARPARKINGMAP: "/carparkingmap",
  NOSMOKING: "/nosmoking",
  VISITORS: "/visitors",
  ENVIRONMENTFRIENDLY: "/ecofriendly",
  HOMEAGEIMAGING: "/imaging",
  RESONICAMAGETICA: "/resonica",
  CONTRASTEDSTUDIES: "/contrased-studies",
  TOMOGRAPHY: "/tomography",
  ULTRASOUND: "/ultrasound",
  CARDIOLOGY: "/cardiology",
  NEUROLOGY: "/neurology",
  XRAYS: "/xrays",
  HOMEPATHOLOGY: "/pathology",
  BLOODBANK: "/blood-bank",
  LABORATORY: "/laboratory",
  PHARMACY: "/pharmacy",
  NURSING: "/nursing",
  NUTRITION: "/nutrition",
  BIOMEDICAL: "/biomedical",
  CUNERO: "/nursery",
  QUIROFANO: "/operating-room",
  INHALOTHERAPY: "/inhalotherapy",
  PHYSICAL_THERAPY: "/physical-therapy",
  ORATORY: "/prayer",
  HEMODYNAMICS: "/hemodynamics",
  INTENSIVE: "/intensive",
  PAYMENT_METHODS: "/payment-methods",
  NICU: "/nicu",
  DIRECTORY: "/directory",
  MATERNITY: "/maternity",
  RESTAURENT: "/restaurant",
  BREAKFAST: "/breakfast",
  LUNCH: "/lunch",
  DINNER: "/dinner",
  DESSERT: "/dessert",
  STARBUCKS: "/starbucks",
  BILLING: "/billing",
  BILLING_FIRST: "/billing-first",
  FURTHER: "/further",
  MORE: "/more",
  CERTIFICATE_FIRST: "/national-certificate",
  INSURANCE: "/insurance",
  PRIVACY_POLICY: "/privacy-policy",
  TERMS_CONDITIONS: "/terms-conditions",
  PLASTIC_SURGRY: "/plastic-surgery",
  WORKWITHUS: "/work-with-us",
  OURHISTORY: "/our-history",
  MEDICALTOWER: "/medical-tower",
  INSURANCEREPORTS: "/insurance-reports",
  BLOGS: "/blogs",
  CORONARYBYPASSSURGERY: "/coronary-bypass",
  PROMOTIONS: "/promotions",
  OURFACALITIES: "/our-facilities",
  GUIDED_BIOPSIES: '/guided-biopsies',
  BRAIN_SURGERY: '/vascular-brain-surgery',
  MECHANICAL_THROMBECTOMY: '/mechanical-thrombectomy'
};

export default PathConstants;
